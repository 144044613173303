import React from 'react'

export const AQIDangerRanges = () => {
    const { HelpText } = info;
    return (
      <div className="flex flex-col m-4 bg-blend-overlay bg-white bg-opacity-25">
        <div className="text-gray-100 bg-blue-400 font-bold p-2 rounded-t-md overflow-hidden flex flex-row justify-start items-center">
          <div style={{ flex: 1 }} className="p-2">
            AQI
          </div>
          <div style={{ flex: 1 }} className="p-2">
            Remark
          </div>
          <div style={{ flex: 1 }} className="p-2">
            Color Code
          </div>
          <div style={{ flex: 3 }} className="p-2">
            Possible Health Impacts
          </div>
        </div>
        {HelpText.map((item, index) => (
          <div
            key={index}
            className="text-gray-700 font-bold p-2 overflow-hidden flex flex-row justify-start items-center gap-2"
          >
            <div style={{ flex: 1 }} className="p-1">
              {item.AQI}
            </div>
            <div style={{ flex: 1 }} className="p-1">
              {item.Remark}
            </div>
            <div
              style={{ flex: 1, backgroundColor: item.Color }}
              className="p-1"
            ></div>
            <div style={{ flex: 3 }} className="p-1">
              {item.Impacts}
            </div>
          </div>
        ))}
      </div>
    );
}

interface HelpTextProps {
  AQI: string;
  Remark: string;
  Color: string;
  Impacts: string;
}

interface infoProps {
  HelpText: HelpTextProps[];
}

export const info: infoProps = {
  HelpText: [
    {
      AQI: "0-50",
      Remark: "Good",
      Color: "#00B050",
      Impacts: "Minimal Impact",
    },
    {
      AQI: "51-100",
      Remark: "Satisfactory",
      Color: "#92B050",
      Impacts: "Minor breathing discomfort to sensitive people",
    },
    {
      AQI: "101-200",
      Remark: "Moderate",
      Color: "#FFFF00",
      Impacts:
        "Breathing discomfort to the people with lungs, asthma and heart diseases",
    },
    {
      AQI: "201-300",
      Remark: "Poor",
      Color: "#FF9900",
      Impacts: "Breathing discomfort to most people on prolonged exposure",
    },
    {
      AQI: "301-400",
      Remark: "Very Poor",
      Color: "#FF0000",
      Impacts: "Respiratory illness on prolonged exposure",
    },
    {
      AQI: "401-500",
      Remark: "Severe",
      Color: "#C00000",
      Impacts:
        "Affects healthy people and seriously impacts those with existing diseases",
    },
  ],
};
